<template>
  <div class="main-body">
    <div id="nav-white" />
    <div id="contact" class="py-5 text-center fadeIn">
      <h1 class="title-md text-darkblue">
        Having troubles? <span class="text-primary">Reach us!</span>
      </h1>

      <div class="d-md-flex justify-content-center mt-5">
        <div class="ru-link mt-3">
          <img src="@/assets/images/icons/main/whatsapp.png" width="35" />
          <h3 class="my-1">WhatsApp</h3>
          <b-button
            variant="primary"
            class="mt-1 contact-btn"
            :href="`https://wa.me/${whatsAppNumber}?text=,%20I%20would%20like%20to%20inquire%20about...`"
            target="
            _blank"
          >
            Send a WhatsApp
          </b-button>
        </div>
        <div class="ru-link mt-3">
          <img src="@/assets/images/icons/main/email.svg" width="35" />
          <h3 class="my-1">Help & Support</h3>
          <b-button
            variant="primary"
            class="mt-1 contact-btn"
            :href="`mailto:${emailAddress}`"
          >
            Send Email
          </b-button>
        </div>
      </div>

      <div id="questions">
        <h1 class="title-md text-darkblue pb-3">
          Frequently asked <span class="text-primary">questions</span>
        </h1>

        <div class="faq-section faq-container px-sm-5">
          <div v-for="(item, index) in questions" :key="index" class="faq-item">
            <button class="question" @click="toggleAnswer(index)">
              {{ item.question }}
              <span :class="{ rotated: activeIndex === index }">
                <i class="fas">&#xf078;</i>
              </span>
            </button>
            <div v-if="activeIndex === index" class="answer">
              {{ item.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";

export default {
  mixins: [intersectionObserverMixin],
  data() {
    return {
      whatsAppNumber: 60172881735,
      emailAddress: "customerservice@glogc.com",
      questions: [
        {
          question: "What services does Golog Malaysia offer?",
          answer:
            "Golog Malaysia provides a range of logistics services, including cold chain solutions, warehousing, freight forwarding, and our SmartTruck service for streamlined delivery shopping and efficient transportation.",
        },
        {
          question:
            "How does Golog ensure the quality of perishable goods during transit?",
          answer:
            "Golog uses temperature-controlled vehicles and advanced monitoring systems to keep perishable items at optimal conditions. Real-time tracking ensures clients can monitor temperature and shipment location at all times.",
        },
        {
          question: "What is the Golog SmartTruck service?",
          answer:
            "Golog SmartTruck is designed to allow drivers to shop directly for deliveries, optimizing route and time efficiency. This service supports drivers with tracking, streamlined pickups, and efficient transport routes.",
        },
        {
          question: "How can I track my shipment with Golog?",
          answer:
            "All shipments are equipped with real-time tracking. You can access the tracking information through our online portal (MiniDC or B2BPro), providing you with live updates on your shipment’s location and estimated arrival.",
        },
        {
          question: "Does Golog handle international shipments?",
          answer:
            "Yes, Golog provides international shipping solutions, including air and sea freight options, for businesses requiring cross-border logistics. We manage customs, documentation, and ensure safe and timely delivery.",
        },
        {
          question: "What industries does Golog Malaysia serve?",
          answer:
            "Golog Malaysia serves various industries, including food and beverage, pharmaceuticals, e-commerce, retail, and manufacturing. We specialize in handling temperature-sensitive and perishable goods, ensuring they are transported safely and efficiently.",
        },
        {
          question: "How do I get a quote for shipping with Golog?",
          answer:
            "You can request a quote through our website by filling out the inquiry form, or you can contact our customer service team directly. Please provide details about your shipment, including dimensions, weight, destination, and service type, so we can provide an accurate quote.",
        },
        {
          question:
            "What should I do if I encounter an issue with my shipment?",
          answer:
            "If you experience any issues with your shipment, please contact our customer service team immediately. We are available via phone, email, or our online chat support to assist you and resolve any concerns as quickly as possible.",
        },
      ],
      activeIndex: null,
    };
  },
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.5);
    this.setupIntersectionObserver(".zoomIn", 0.1);
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    toggleCollapse(index) {
      this.$set(this.isOpen, index, !this.isOpen[index]);
    },
  },
};
</script>
<style scoped>
.contact-btn {
  width: 165px !important;
  text-align: center;
  font-size: 1rem;
  height: 40px !important;
}
</style>
